import { css } from '@emotion/css';
import authApi from 'apis/authApi';
import LoadingIcon from 'components/loading/LoadingIcon';
import RegisterForm from 'components/RegisterForm';
import { colors } from 'constants/theme';
import { RegisterPayload } from 'models/authModel';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import ToastRoot from 'components/ToastAlert';
import { isMobile } from 'react-device-detect';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import Register from 'components/reg/Register';

interface DomainItem {
    link: string;
    ping: number;
}

const domainArr: DomainItem[] = [
    { link: 'https://alobet88.com', ping: 0 },
    { link: 'https://alobet88.info', ping: 0 },
    { link: 'https://alobet88.live', ping: 0 },
    { link: 'https://alobet88.net', ping: 0 },
    { link: 'https://alobet18.com', ping: 0 },
    { link: 'https://alobet18.net', ping: 0 },
];
let idInterVal: any;

function App() {
    const domainList = useRef<DomainItem[]>([]);
    const loadedDomain = useRef<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [secondDown, setSecondDown] = useState(6);
    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        testDomainAvailable(domainArr, true);
        return () => {
            idInterVal && clearInterval(idInterVal);
        };
    }, []);

    const getDomain = async () => {
        try {
            const { data } = await authApi.getDomainAvailable({});
            let domainData: DomainItem[] = [];
            if (!data.msg) {
                if (data.domainList?.length > 0) {
                    data.domainList.forEach((link: string) => {
                        domainData.push({ link, ping: 0 });
                    });
                }
            }
            domainData = domainData.length > 0 ? domainData : domainArr;
            testDomainAvailable(domainData, false);
        } catch (error) {
            console.log(error);
        }
    };

    const testDomainAvailable = (list: DomainItem[], init: boolean) => {
        list = checkPlatform(list);
        try {
            const domainPass = list.map(async (item) => {
                const res = await pingDomain(item.link);

                const speed = parseInt(res, 10);
                return { ...item, ping: speed };
            });

            domainPass.forEach((promise) => {
                promise.then((res) => {
                    domainList.current.push(res);
                    if (init && !loadedDomain.current) {
                        loadedDomain.current = true;
                        if (domainList.current.length > 0) {
                            const link = domainList.current[0].link;
                            getDomain();
                        }
                    }
                });
            });
        } catch (error) {
            console.log(error);
        }
    };

    const checkPlatform = (list: DomainItem[]) => {
        if (isMobile) return list.map((item) => ({ ...item, link: item.link.replace('https://', 'https://m.') }));
        return list;
    };

    const pingDomain = (url: string): Promise<any> => {
        const promise = new Promise((resolve, reject) => {
            const startTime = new Date().getTime();
            const response = () => {
                const getTimeLoad = new Date().getTime() - startTime;
                resolve(getTimeLoad);
            };

            requestImg(url)
                .then(response)
                .catch((err) => {
                    console.error(err);
                });
        });
        return promise;
    };

    const requestImg = (url: string) => {
        let imgName = '/favicon.png';

        if (isMobile) {
            imgName = '/assets/images/favicon.png';
        }
        const promise = new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                resolve(img);
            };
            img.onerror = () => {
                reject(url);
            };
            img.src = url + imgName + '?clearnCache=' + Math.floor((1 + Math.random()) * 0x10000).toString(16);
        });
        return promise;
    };

    const submitRegister = async (values: RegisterPayload) => {
        setLoading(true);
        try {
            const { data } = await authApi.signUp(values);
            if (!data.msg) {
                //thông báo đăng ký tài khoản thành công
                setShowSuccess(true);
                idInterVal = setInterval(() => {
                    setSecondDown((value) => {
                        if (value === 0) {
                            clearInterval(idInterVal);
                            goToHome();
                            return 0;
                        }
                        return value - 1;
                    });
                }, 1000);
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const goToHome = () => {
        idInterVal && clearInterval(idInterVal);
        if (domainList.current.length > 0) {
            window.location.href = domainList.current[0].link;
        }
    };
    return (
        <>
            <div className={styles.page}>
                {/* <div className={styles.logo} onClick={goToHome}>
                    <img src={require('assets/images/logo.png')} alt="" />
                </div>
                <div className={styles.formWrap}>
                    <h3 className={styles.titleForm}>ĐĂNG KÝ TÀI KHOẢN</h3>
                    <RegisterForm onRegister={submitRegister} />

                    <p className={styles.hasAccount}>
                        Bạn đã có tài khoản! <span onClick={goToHome}>Đăng nhập ngay</span>
                    </p>
                </div> */}
                <Register onRegister={submitRegister} goToHome={goToHome} />
                <div className={`${styles.alertSucess} ${showSuccess ? 'show' : ''}`}>
                    <div className={`${styles.alertContent} ${showSuccess ? 'show' : ''}`}>
                        <BsFillCheckCircleFill size={80} color="#34954D" />
                        <h4>Đăng ký thành công!</h4>
                        <p>
                            Tự động chuyển đến trang chủ sau <span>{secondDown}</span> giây
                        </p>
                        <button type="button" onClick={goToHome}>
                            Chuyển ngay
                        </button>
                    </div>
                </div>
            </div>
            <ToastRoot />

            {loading && <LoadingIcon />}
        </>
    );
}

const styles = {
    page: css`
        background: ${colors.border};
        height: 100vh;
        overflow-y: auto;
        padding: 24px 16px;
        @media (max-width: 420px) {
            padding: 16px;
        }
    `,
    logo: css`
        cursor: pointer;
        @media (max-width: 420px) {
            text-align: center;
        }
        img {
            @media (max-width: 420px) {
                width: 180px;
            }
        }
    `,
    titleForm: css`
        margin: 24px 0;
        font-size: 32px;
        color: ${colors.white};
        text-align: center;
        @media (max-width: 420px) {
            font-size: 22px;
            margin: 16px 0;
        }
    `,
    formWrap: css`
        width: 100%;
        max-width: 550px;
        margin: 0 auto;
    `,
    alertSucess: css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        pointer-events: none;
        &.show {
            opacity: 1;
            pointer-events: initial;
        }
    `,
    alertContent: css`
        background: ${colors.white};
        padding: 12px;
        border-radius: 12px;
        text-align: center;
        transform: scale(0.8);
        transition: all.25s;
        &.show {
            transform: scale(1);
        }
        h4 {
            padding-top: 12px;
            color: #34954d;
            font-weight: 700;
            font-size: 18px;
        }
        p {
            color: ${colors.gray};
            padding-top: 4px;
            padding-bottom: 12px;
        }
        button {
            padding: 8px 12px;
            border-radius: 8px;
            background: ${colors.secondary};
            border: none;
            cursor: pointer;
        }
    `,
    hasAccount: css`
        color: ${colors.white};
        text-align: center;
        span {
            color: ${colors.primary};
            font-style: italic;
            cursor: pointer;
        }
    `,
};

export default App;
